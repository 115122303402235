<template>
  <div>
    <div>
      <div>
        <el-button v-throttle size="medium" @click="goBack">返回</el-button>
      </div>

      <div id="exam-name-box">
        <span>2022广州市葫芦娃七校联考</span>
      </div>

      <div style="margin: 15px 0">2020/03/24 09:45——2020/03/24 10:00</div>

      <div>
        <span>科目：物理，生物</span>
        <span class="header-count-badge">考点：长鹏中学</span>
        <span class="header-count-badge">考试时长：23min</span>
        <span class="header-count-badge">考生人数：100人</span>
        <span class="header-count-badge">考试批次：10批</span>
      </div>

      <div style="margin: 15px 0">
        <el-button v-throttle type="primary" size="medium"
          >上传考后数据</el-button
        >
        <el-button v-throttle type="primary" size="medium"
          >确认考后数据</el-button
        >
      </div>

      <div>
        <span>最近更新时间：2022-12-21 21:22:09</span>
        <span style="margin-left: 50px">确认时间：2022-12-21 21:22:09</span>
      </div>
    </div>

    <div id="score-info-box">
      <el-card shadow="always">
        <!-- <p>成绩明细</p> -->
        <div>
          <el-form :inline="true" :model="queryParam">
            <el-form-item label="考生查询">
              <el-input
                style="width: 160px"
                v-model="queryParam.name"
                placeholder="输入姓名/考号"
              ></el-input>
            </el-form-item>

            <el-form-item label="区县">
              <el-select v-model="queryParam.gender" placeholder="请选择区县">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="学校">
              <el-select v-model="queryParam.gender" placeholder="请选择学校">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="班级">
              <el-select v-model="queryParam.gender" placeholder="请选择班级">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="批次">
              <el-select v-model="queryParam.gender" placeholder="请选择批次">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="科目">
              <el-select v-model="queryParam.gender" placeholder="请选择科目">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="上传情况">
              <el-select
                v-model="queryParam.gender"
                placeholder="请选择上传情况"
              >
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <!--   <el-form-item label="评分范围">
              <el-input
                v-model="queryParam.scoreStart"
                @input="e=>queryParam.scoreStart=$verifyInput(e,1)"
                class="inputScore"
              ></el-input>&nbsp;至&nbsp;
              <el-input
                v-model="queryParam.scoreEnd"
                @input="e=>queryParam.scoreEnd=$verifyInput(e,1)"
                class="inputScore"
              ></el-input>
            </el-form-item>-->

            <el-form-item>
              <el-button
                v-throttle
                type="primary"
                icon="el-icon-refresh"
                @click="refresh"
              ></el-button>
            </el-form-item>
          </el-form>
        </div>

        <div>
          <el-table
            v-loading="loading"
            stripe
            border
            :data="list"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="nickName"
              label="考生姓名"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="考号"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="区县"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="学校"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="考试批次"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="科目"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="考题"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="考试视频"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="考试成绩"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="实验报告图片"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="显微镜图片"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="签名图片"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="account"
              label="上传情况"
              show-overflow-tooltip
            ></el-table-column>
          </el-table>
        </div>

        <div style="margin-top: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageParam.pageIndex"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- <div id="score-dissent-info-box">
      <el-card shadow="always">
        <p>成绩异议明细</p>
        <div>
          <el-form :inline="true" :model="queryDissentParam">
            <el-form-item label="姓名">
              <el-input v-model="queryDissentParam.name" placeholder="请输入姓名"></el-input>
            </el-form-item>

            <el-form-item label="考号">
              <el-input v-model="queryDissentParam.name" placeholder="请输入考号"></el-input>
            </el-form-item>

            <el-form-item label="区县">
              <el-select v-model="queryDissentParam.gender" placeholder="请选择区县">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="学校">
              <el-select v-model="queryDissentParam.gender" placeholder="请选择学校">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="科目">
              <el-select v-model="queryDissentParam.gender" placeholder="请选择科目">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="状态">
              <el-select v-model="queryDissentParam.gender" placeholder="请选择状态">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button v-throttle  type="primary" icon="el-icon-refresh" @click="refresh(2)"></el-button>
            </el-form-item>
          </el-form>
        </div>

        <div>
          <el-table
            v-loading="loading"
            stripe
            border
            :data="list"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
          >
            <el-table-column prop="nickName" label="姓名" show-overflow-tooltip></el-table-column>
            <el-table-column prop="account" label="考号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="account" label="区县" show-overflow-tooltip></el-table-column>
            <el-table-column prop="account" label="学校" show-overflow-tooltip></el-table-column>
            <el-table-column prop="account" label="考试时间" show-overflow-tooltip></el-table-column>
            <el-table-column prop="account" label="科目" show-overflow-tooltip></el-table-column>
            <el-table-column prop="account" label="成绩" show-overflow-tooltip></el-table-column>
            <el-table-column prop="account" label="申请理由" show-overflow-tooltip></el-table-column>
            <el-table-column prop="account" label="申请时间" show-overflow-tooltip></el-table-column>
            <el-table-column prop="account" label="审批时间" show-overflow-tooltip></el-table-column>
            <el-table-column prop="account" label="状态" show-overflow-tooltip></el-table-column>
          </el-table>
        </div>

        <div style="margin-top: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageParam.pageIndex"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-card>
    </div>-->
  </div>
</template>

<script>
export default {
  name: 'middleExamination',
  data() {
    return {
      queryParam: {
        name: '',
        gender: 'shanghai',
        scoreStart: '',
        scoreEnd: '',
      },
      queryDissentParam: {
        name: '',
        gender: 'shanghai',
      },
      loading: false,
      list: [],
      pageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        path: '/schoolManufacturer/examManage',
        query: {},
      })
    },
    refresh() {
      this.resetQueryParam()
    },
    resetQueryParam() {
      this.queryParam.name = ''
      this.queryParam.gender = ''
      this.queryParam.scoreStart = ''
      this.queryParam.scoreEnd = ''
    },
    handleCurrentChange(val) {},
    handleSizeChange(val) {},
  },
}
</script>

<style lang="scss" scoped>
#exam-name-box {
  margin-top: 15px;
}

#exam-name-box > span {
  font-size: 28px;
}

.header-count-badge {
  margin-left: 30px;
}

#score-info-box ::v-deep .el-form .el-form-item__content .el-select {
  width: 160px;
}

#score-info-box,
#score-dissent-info-box {
  margin-top: 20px;
}

#score-info-box > p {
  color: #909399;
}

#score-dissent-info-box > p {
  font-weight: bold;
}

.inputScore {
  width: 60px;
}

.inputScore > ::v-deep .el-input__inner {
  text-align: center;
}
</style>
